import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import edit from "../assets/images/icons/edit.png";
import PageHeader from "../Components/PageHeader";
import Table from "../Components/Table";
import TableContent from "../Components/TableContent";
import TableHeader from "../Components/TableHeader";
import * as Yup from "yup";
import InputField from "../Components/InputField";
import request, { URL } from "../services/request";
import endPoint from "../services/endPoint";
import { AppContext } from "../services/context";
import Notify from "../Components/Notify";
import { useLocation } from "react-router-dom";

const initCategorie = {
  image: "",
  titre: "",
  contenu: "",
  htmlOne: "",
  date_debut: "",
  date_fin: "",
  parent_id: "",
  //code: "09_dsfdO0_fssdfsd",
};
const Evenement = () => {
  const authCtx = useContext(AppContext);
  const { user } = authCtx;
  const [datas, setDatas] = useState([]);
  const [editId, setEditId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [viewData, setViewData] = useState({});
  const [refresh, setRefresh] = useState(0);
  const location = useLocation();
  const paths = location.pathname.split("/");
  const header = {
    headers: {
      Authorization: `Bearer ${user.token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  useEffect(() => {
    get();
  }, [refresh]);
  const validateData = Yup.object({
    label: Yup.string()
      .min(3, "Le nom de la catégorie doit contenir 3 caractères ou moins")
      .required("Ce champ est obligatoire. Veuillez le remplir pour continuer"),
    image: Yup.mixed()
      .required("Une image est requise")
      .test(
        "fileFormat",
        "Seuls les fichiers jpeg, png et gif sont autorisés",
        (value) => {
          return (
            value &&
            ["image/jpeg", "image/png", "image/gif"].includes(value.type)
          );
        }
      )
      .test("fileSize", "La taille maximale autorisée est de 2 Mo", (value) => {
        return value && value.size <= 2 * 1024 * 1024;
      }),
  });
  const formik = useFormik({
    initialValues: initCategorie,
    //validationSchema: validateData,
    onSubmit: (values) => {
      console.log(values);
      if (editId === "") {
        values.parent = datas.id;
        handleSubmit(values);
      } else {
        values._method = "put";
        handleEditSubmit(values);
      }
    },
  });

  const get = () => {
    request
      .get(endPoint.posts + "/" + paths[2], header)
      .then((res) => {
        setDatas(res.data.data);
        console.log(res.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleSubmit = (data) => {
    //setShowModal(true)
    request
      .post(endPoint.categories, data, header)
      .then((res) => {
        console.log("Enregistrer avec succès");
        setRefresh(refresh + 1);
        console.log(res.data);
        get();
      })
      .catch((error) => {
        console.log("Echec !");
        console.log(error);
      });
  };
  const handleEditSubmit = (data) => {
    //setShowModal(true)
    request
      .post(endPoint.categories + "/" + editId, data, header)
      .then((res) => {
        console.log("Enregistrer avec succès");
        setEditId("");
        setRefresh(refresh + 1);
        console.log(res);
        get();
      })
      .catch((error) => {
        console.log("Echec !");
        console.log(error);
      });
  };

  const onDelete = (e) => {
    e.preventDefault();
    request
      .delete(endPoint.categories + "/" + editId, header)
      .then((res) => {
        console.log(res.data);
        setRefresh(refresh + 1);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addModal = (e) => {
    e.preventDefault();
    setEditId("");
    formik.resetForm();
  };

  const editData = (e, data) => {
    e.preventDefault();
    formik.setFieldValue("titre", data.titre);
    formik.setFieldValue("date_debut", data.date_debut);
    formik.setFieldValue("date_fin", data.date_fin);
    formik.setFieldValue("htmlOne", data.htmlOne);
    formik.setFieldValue("contenu", data.contenu);
    setEditId(data.id);
  };
  return (
    <>
      <PageHeader
        title="Liste des évènements"
        modal="formModal"
        addModal={addModal}
      />
      <Table>
        <TableHeader>
          <th scope="col" className="border-raduis-left">
            #
          </th>
          <th scope="col">Image</th>
          <th scope="col">Intitule</th>
          <th scope="col">Debut</th>
          <th scope="col">Fin</th>
          <th scope="col" className="text-center">
            Actions
          </th>
        </TableHeader>
        <TableContent>
          {datas.toutes_sous_categories?.map((data, idx) => {
            if (data.is_deleted) {
              return null;
            }
            return (
              <tr key={idx}>
                <td>
                  <input type="checkbox" value="selected" />
                </td>
                <td className="fw-bold">
                  <img
                    width="80px"
                    height="80px"
                    className="rounded-circle"
                    src={URL + data.image}
                    alt=""
                  />
                </td>
                <td className="fw-bold">{data.titre}</td>
                <td className="fw-bold">{data.date_debut}</td>
                <td className="fw-bold">{data.date_fin}</td>
                <td className="text-center">
                  <div className="btn-group">
                    <div className="d-inline-block mx-1">
                      <button
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#viewModal"
                        onClick={(e) => {
                          setViewData(data);
                        }}
                      >
                        <img src={edit} alt="" />
                        <span> Voir</span>
                      </button>
                    </div>
                    <div className="d-inline-block mx-1">
                      <button
                        className="btn btn-primary-light"
                        data-bs-toggle="modal"
                        data-bs-target="#formModal"
                        onClick={(e) => {
                          editData(e, data);
                        }}
                      >
                        <img src={edit} alt="" />
                        <span> Modifier</span>
                      </button>
                    </div>
                    <div className="d-inline-block mx-1">
                      <button
                        className="btn btn-danger"
                        data-bs-toggle="modal"
                        data-bs-target="#deleteModal"
                        onClick={(e) => {
                          setEditId(data.id);
                        }}
                      >
                        <img src={edit} alt="" />
                        <span> Supprimer</span>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </TableContent>
      </Table>
      <div className="modal fade" id="viewModal">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h4 className="modal-title text-meduim text-bold">
                informations
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body overflow-auto">
              <div>
                <div>
                  <span>Titre : </span>
                  <span className="fw-bold">{viewData.titre}</span>
                </div>
                <div>
                  <span>Date de début : </span>
                  <span className="fw-bold">{viewData.date_debut}</span>
                </div>
                <div>
                  <span>Date de fin : </span>
                  <span className="fw-bold">{viewData.date_fin}</span>
                </div>
              </div>
              <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: viewData.contenu,
                    }}
                  />
                </div>
              <div className="d-flex justify-content-start border-0 mt-4">
                <button
                  type="reset"
                  className="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </button>
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                >
                  Fermer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="formModal">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h4 className="modal-title text-meduim text-bold">
                {editId !== ""
                  ? "Modification d’un évènement"
                  : "Ajout d’un évènement"}
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <form onSubmit={formik.handleSubmit}>
                <InputField
                  type={"text"}
                  name="titre"
                  formik={formik}
                  placeholder="Entrez le nom de l'évènement"
                  label={"Nom de l'évènement"}
                />
                <InputField
                  type={"date"}
                  name="date_debut"
                  formik={formik}
                  placeholder=""
                  label={"Date de début"}
                />
                <InputField
                  type={"date"}
                  name="date_fin"
                  formik={formik}
                  placeholder=""
                  label={"Date de fin"}
                />
                <InputField
                  type={"file"}
                  name="image"
                  formik={formik}
                  placeholder="Choisir une image"
                  label={"Choisissez une image de la campagne"}
                />
                <InputField
                  type={"textEditor"}
                  name="contenu"
                  formik={formik}
                  placeholder="Entrez la description l'évènement"
                  label={"Description l'évènement"}
                />
                <div className="d-flex justify-content-start border-0">
                  <button
                    type="reset"
                    className="btn btn-secondary me-2"
                    data-bs-dismiss="modal"
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary"
                    data-bs-dismiss="modal"
                  >
                    Enregistrer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="modal fade" id="deleteModal">
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header border-0">
              <h4 className="modal-title text-meduim text-bold">
                Suppression de données
              </h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body">
              <div>
                Vous êtes sur le point de supprimer définitivement des
                informations
              </div>

              <div className="d-flex justify-content-start border-0 mt-4">
                <button
                  type="reset"
                  className="btn btn-secondary me-2"
                  data-bs-dismiss="modal"
                >
                  Annuler
                </button>
                <button
                  className="btn btn-danger"
                  data-bs-dismiss="modal"
                  onClick={onDelete}
                >
                  Continuer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Notify showModal={showModal} />
    </>
  );
};

export default Evenement;

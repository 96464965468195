import React, { useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";

const modules = {
  toolbar: [
    [{ font: [] }], // fonts
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ size: ["small", false, "large", "huge"] }],
    ["bold", "italic", "underline", "strike"],
    [{ align: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
      { list: "check" },
    ],
    ["link", "image", "video", "formula"],

    //[{ size: ["12px", "14px", "16px", "18px", "20px"] }],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    ["clean"],
  ],
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
  "strike",
  "script",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "code-block",
];

const InputField = ({
  type,
  formik,
  label,
  placeholder,
  name,
  children,
  options = [],
}) => {
  if (type === "text") {
    return (
      <div className="mb-3">
        {label && (
          <label className="form-label" htmlFor={name}>
            {label}
          </label>
        )}
        <input
          className="form-control"
          type="text"
          id={name}
          name={name}
          placeholder={placeholder}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
        />
        {children}
        {formik.touched[name] && formik.errors[name] ? (
          <div className="text-danger">{formik.errors[name]}</div>
        ) : null}
      </div>
    );
  }

  if (type === "date") {
    return (
      <div className="mb-3">
        {label && (
          <label className="form-label" htmlFor={name}>
            {label}
          </label>
        )}
        <input
          className="form-control"
          type="date"
          id={name}
          name={name}
          placeholder={placeholder}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
        />
        {children}
        {formik.touched[name] && formik.errors[name] ? (
          <div className="text-danger">{formik.errors[name]}</div>
        ) : null}
      </div>
    );
  }

  if (type === "email") {
    return (
      <div className="mb-3">
        {label && (
          <label className="form-label" htmlFor={name}>
            {label}
          </label>
        )}
        <input
          className="form-control"
          type="email"
          id={name}
          name={name}
          placeholder={placeholder}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
        />
        {formik.touched[name] && formik.errors[name] ? (
          <div className="text-danger">{formik.errors[name]}</div>
        ) : null}
      </div>
    );
  }

  if (type === "password") {
    return (
      <div className="mb-3">
        {label && (
          <label className="form-label" htmlFor={name}>
            {label}
          </label>
        )}
        <input
          className="form-control"
          type="password"
          id={name}
          name={name}
          placeholder={placeholder}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
        />
        {children}
        {formik.touched[name] && formik.errors[name] ? (
          <div className="text-danger">{formik.errors[name]}</div>
        ) : null}
      </div>
    );
  }

  if (type === "file") {
    return (
      <div className="mb-3">
        {label && (
          <label className="form-label" htmlFor={name}>
            {label}
          </label>
        )}
        <input
          className="form-control"
          type="file"
          id={name}
          name={name}
          placeholder={placeholder}
          onChange={(e) => formik.setFieldValue(name, e.target.files[0])}
          onBlur={formik.handleBlur}
          //value={formik.values[name]}
        />
        {children}
        {formik.touched[name] && formik.errors[name] ? (
          <div className="text-danger">{formik.errors[name]}</div>
        ) : null}
      </div>
    );
  }

  if (type === "select") {
    return (
      <div className="mb-3">
        {label && (
          <label className="form-label" htmlFor={name}>
            {label}
          </label>
        )}
        <select
          className="form-select"
          type="text"
          id={name}
          name={name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
        >
          <option value={""}>{placeholder}</option>
          {options.map((data, idx) => {
            return (
              <option key={idx} value={data.id}>
                {data.label}
              </option>
            );
          })}
        </select>
        {children}
        {formik.touched[name] && formik.errors[name] ? (
          <div className="text-danger">{formik.errors[name]}</div>
        ) : null}
      </div>
    );
  }

  if (type === "textarea") {
    return (
      <div className="mb-3">
        {label && (
          <label className="form-label" htmlFor={name}>
            {label}
          </label>
        )}
        <textarea
          className="form-control"
          id={name}
          name={name}
          placeholder={placeholder}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[name]}
          rows="4"
        ></textarea>
        {children}
        {formik.touched[name] && formik.errors[name] ? (
          <div className="text-danger">{formik.errors[name]}</div>
        ) : null}
      </div>
    );
  }

  if (type === "textEditor") {
    return (
      <div>
        {label && (
          <label className="form-label" htmlFor={name}>
            {label}
          </label>
        )}
        <ReactQuill
          theme="snow"
          modules={modules}
          formats={formats}
          value={formik.values[name]}
          placeholder={placeholder}
          onChange={(e) => formik.setFieldValue(name, e)}
          style={{
            height: "400px",
            //height: "100%",
            /* added these styles */
            flex: 1,
            "overflow-y": "auto",
            width: "100%",
          }}
        />
      </div>
    );
  }

  return null;
};

export default InputField;

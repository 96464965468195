
const endPoint = {
    login:"login",
    posts:"posts",
    categories:"posts",

    produits:"produits",
    promotions:"promotions",
    livreurs:"livreurs",
    fournisseurs:"fournisseurs",
    clients:"clients",
    administrateurs:"administrateurs",
}

export default endPoint